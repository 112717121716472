import * as React from 'react'
import {graphql, useStaticQuery} from "gatsby";
import Button from "./button";
import {GatsbyImage} from "gatsby-plugin-image";

export default function Hero(){
    const data = useStaticQuery(graphql`
{
  fileName: file(relativePath: {eq: "images/hero-home.jpg"}) {
    childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            width: 1080
            layout: FULL_WIDTH
          )
        }
  }
  allDataJson {
    edges {
        node {
            About_Us
            Better_Explanation
            Business_Name
            Contact_Details {
                Address
                Email
                Hours
                Phone
                Phone_Without_Formatting
                Service_Areas
            }
            Expert_Explanation
            Main_Services {
                Description
                Title
            }
            Other_Services {
                Description
                Title
            }
            Pages {
                Image
                Title
                Page_Address
            }
            Qualities
            Tag_Line
            Title
            Years_Experience
        }
    }
  }
}



`)
    const WebsiteContent = data.allDataJson.edges[0].node

    const ImageContent = data.fileName.childImageSharp.gatsbyImageData;

    return(
        <div>
            <div className={"w-full h-screen absolute overflow-hidden top-0 -z-10 brightness-50 dark:brightness-25"}>
                <GatsbyImage className={"h-full"} alt={"hero image"} objectPosition={"100% 0%"}  objectFit={"cover"} image={ImageContent} layout={"fullWidth"} />
            </div>
                <div className={"h-full lg:py-18 lg:pb-20 pb-0 pt-14 flex flex-col justify-center items-center max-w-3xl mx-auto font-bold"}>
                    <h1 className={"lg:leading-relaxed text-5xl lg:text-6xl text-center text-white p-6 "}>{WebsiteContent.Title}</h1>
                    <div className={"h-1 w-24 bg-main-color-500"}></div>
                    <p className={"text-md text-center text-white p-6 pt-10 lg:pt-10 leading-loose"}>{WebsiteContent.Tag_Line}</p>
                    <Button className={""} to={"/contact#contact-form"}>Get a Free Quote!</Button>
                    <div className={"flex flex-col justify-center items-center lg:p-3 py-3 px-3 bg-white rounded-xl mt-14 mb-4"}>
                    <div className={"text-main-color-500 text-xl p-4  text-center border-4 border-main-color-500 border-dashed bg-white rounded-xl"}>
                    <p className={"pb-2 text-3xl"}>Current Specials</p>
                    <p className={"pb-2"}>Drain Cleaning - $89</p>
                    <p>Hydro Jetting - $225</p>
                    </div>
                    </div>
                </div>
        </div>
    )
}